<template>
  <div class="elv-valuation-crypto-table-wrapper-container">
    <el-table
      ref="tableListRef"
      v-loading="props.tableLoading"
      :data="props.tableData"
      row-key="key"
      border
      height="100%"
      class="elv-valuation-crypto-table"
      header-cell-class-name="elv-valuation-crypto-table-header__cell"
      header-row-class-name="elv-valuation-crypto-table-header"
      row-class-name="elv-valuation-crypto-table-row"
      cell-class-name="elv-valuation-crypto-table-row__cell"
      :tree-props="{ children: 'list' }"
      default-expand-all
    >
      <el-table-column width="40" align="center" class-name="elv-valuation-crypto-table-row__cell-index">
        <template #default="{ row }">
          <div>{{ row.index }}</div>
        </template>
      </el-table-column>
      <el-table-column :label="t('common.currency')" class-name="is-number" width="180">
        <template #default="{ row }">
          <IconWithTextCell
            v-if="row.type === 'ASSET_TITLE' || row.type === 'LIABILITY_TITLE'"
            :title="row.type === 'ASSET_TITLE' ? t('report.Assets') : t('valuation.crypto.liabilities')"
            :memo="row.count"
            :is-memo-bold="true"
          />
          <IconWithTextCell
            v-else
            :icon="row.underlyingCurrency?.logo"
            :title="row.underlyingCurrency?.name"
            :memo="row.underlyingCurrency?.showSymbol"
          />
        </template>
      </el-table-column>
      <el-table-column :label="t('valuation.crypto.beginFairValue')" min-width="150">
        <template #default="{ row }">
          <TextCell
            v-if="row.type"
            :text="
              formatNumberToSignificantDigits(
                row.beginFairValue,
                2,
                `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            "
            :titleDesc="
              fieldValueFormat(
                row.beginFairValue,
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )
            "
            :weight="600"
            align="right"
          />
          <VerticalTextCell
            v-else
            :upText="
              formatNumberToSignificantDigits(
                row.beginFairValue,
                2,
                `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            "
            :upDesc="
              fieldValueFormat(
                row.beginFairValue,
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )
            "
            :downText="formatNumberToSignificantDigits(row.beginAmount, 2, '', false)"
            :downDesc="formatNumber(row.beginAmount, 20)"
            :upWeight="500"
          />
        </template>
      </el-table-column>

      <el-table-column :label="t('valuation.crypto.endFairValue')" min-width="140">
        <template #default="{ row }">
          <TextCell
            v-if="row.type"
            :text="
              formatNumberToSignificantDigits(
                row.endingFairValue,
                2,
                `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            "
            :titleDesc="
              fieldValueFormat(
                row.endingFairValue,
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )
            "
            :weight="600"
            align="right"
          />
          <VerticalTextCell
            v-else
            :upText="
              formatNumberToSignificantDigits(
                row.endingFairValue,
                2,
                `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            "
            :upDesc="
              fieldValueFormat(
                row.endingFairValue,
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )
            "
            :downText="formatNumberToSignificantDigits(row.endingAmount, 2, '', false)"
            :downDesc="formatNumber(row.endingAmount, 20)"
            :upWeight="500"
          />
        </template>
      </el-table-column>
      <el-table-column :label="t('valuation.crypto.costBasis')" min-width="130">
        <template #default="{ row }">
          <TextCell
            :text="
              formatNumberToSignificantDigits(
                row.costBasis,
                2,
                `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            "
            :titleDesc="
              fieldValueFormat(
                row.costBasis,
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )
            "
            :weight="row.type ? 600 : 500"
            align="right"
          />
        </template>
      </el-table-column>
      <el-table-column :label="t('valuation.crypto.unrealizedGainLoss')" min-width="160">
        <template #default="{ row }">
          <TextCell
            :text="
              formatNumberToSignificantDigits(
                row.unrealizedGainLoss,
                2,
                `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            "
            :titleDesc="
              fieldValueFormat(
                row.unrealizedGainLoss,
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )
            "
            :weight="row.type ? 600 : 500"
            align="right"
          />
        </template>
      </el-table-column>
      <el-table-column :label="t('valuation.crypto.shortTermRealizedGainLoss')" min-width="200">
        <template #default="{ row }">
          <TextCell
            :text="
              row.shortTermRealizedGainLoss
                ? formatNumberToSignificantDigits(
                    row.shortTermRealizedGainLoss,
                    2,
                    `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                  )
                : '-'
            "
            :titleDesc="
              fieldValueFormat(
                row.shortTermRealizedGainLoss ?? '',
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )
            "
            :weight="row.type ? 600 : 500"
            align="right"
          />
        </template>
      </el-table-column>
      <el-table-column :label="t('valuation.crypto.longTermRealizedGainLoss')" min-width="200">
        <template #default="{ row }">
          <TextCell
            :text="
              row.longTermRealizedGainLoss
                ? formatNumberToSignificantDigits(
                    row.longTermRealizedGainLoss,
                    2,
                    `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                  )
                : '-'
            "
            :titleDesc="
              fieldValueFormat(
                row.longTermRealizedGainLoss ?? '',
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )
            "
            :weight="row.type ? 600 : 500"
            align="right"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup lang="ts">
import { useEntityStore } from '@/stores/modules/entity'
import TextCell from '@/components/Base/Table/Cell/TextCell.vue'
import VerticalTextCell from '@/components/Base/Table/Cell/VerticalTextCell.vue'
import IconWithTextCell from '@/components/Base/Table/Cell/IconWithTitleCell.vue'
import { formatNumber, fieldValueFormat, formatNumberToSignificantDigits } from '@/lib/utils'

const props = defineProps({
  tableData: {
    type: Array,
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  params: {
    type: Object,
    required: true
  }
})

const { t } = useI18n()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)

const tableListRef = useTemplateRef('tableListRef')
</script>

<style lang="scss">
.elv-valuation-crypto-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  --el-fill-color-lighter: #f9fafb;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  border-right: 0px;
  border-left: 0px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body-wrapper {
    height: 616px;

    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  .elv-valuation-crypto-table-header {
    background: #eef4fb;

    .elv-valuation-crypto-table-header__cell {
      background: #eef4fb;
      height: 36px;
      box-sizing: border-box;
      border-right-color: #e4e7eb;
      border-bottom-color: #ced7e0;
      padding: 0px;

      &:last-child {
        border-right: 0px;
      }

      &.is-center {
        .cell {
          justify-content: center;
        }
      }

      &.is-right .cell {
        justify-content: flex-end;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  .el-table__row.elv-valuation-crypto-table-row {
    background-color: #fff;

    &.el-table__row--level-0 .elv-valuation-crypto-table-row__cell,
    .elv-valuation-crypto-table-row__cell {
      background-color: #f9fafb;

      .cell {
        font-size: 13px;
        font-weight: 600;
        word-break: break-word;
      }

      span {
        padding-left: 10px;
      }
    }

    &.el-table__row--level-1 .elv-valuation-crypto-table-row__cell {
      background-color: #ffffff;

      &.elv-valuation-crypto-table-row__accountName {
        .cell {
          font-weight: 500;
        }
      }

      &.elv-valuation-crypto-table-row__accountId span {
        padding-left: 30px;
      }
    }

    &.el-table__row--level-2 .elv-valuation-crypto-table-row__cell {
      background-color: #f9f9f9;

      &.elv-valuation-crypto-table-row__accountName {
        .cell {
          font-weight: 500;
        }
      }

      &.elv-valuation-crypto-table-row__accountId span {
        padding-left: 50px;
      }
    }

    &.el-table__row--level-3 .elv-valuation-crypto-table-row__cell {
      background-color: #f9f9f9;

      &.elv-valuation-crypto-table-row__accountName {
        .cell {
          font-weight: 500;
        }
      }

      &.elv-valuation-crypto-table-row__accountId span {
        padding-left: 70px;
      }
    }

    &.el-table__row--level-4 .elv-valuation-crypto-table-row__cell {
      background-color: #f9f9f9;

      &.elv-valuation-crypto-table-row__accountName {
        .cell {
          font-weight: 500;
        }
      }

      &.elv-valuation-crypto-table-row__accountId span {
        padding-left: 90px;
      }
    }

    &.el-table__row--level-5 .elv-valuation-crypto-table-row__cell {
      background-color: #f9f9f9;

      &.elv-valuation-crypto-table-row__accountName {
        .cell {
          font-weight: 500;
        }
      }

      &.elv-valuation-crypto-table-row__accountId span {
        padding-left: 110px;
      }
    }
  }

  .elv-valuation-crypto-table-row__cell {
    padding: 0;
    height: 42px;
    cursor: pointer;

    &:last-child {
      border-right: 0px;
    }

    &.is-right .cell {
      text-align: right;
    }

    &.is-center .cell {
      text-align: center;
    }

    &.is-number .cell {
      font-family: 'Barlow';
      font-weight: 500;
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;

      .el-table__indent {
        padding-left: 0px !important;
        border-left: 0px;
      }

      .el-table__placeholder {
        width: 40px;
        box-sizing: border-box;
        height: 100%;
        padding-left: 40px !important;
        border-left: 0px;
      }

      span {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid #edf0f3;
        white-space: nowrap;
      }

      .el-table__expand-icon {
        width: 40px;
        height: 100%;
        display: none;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        margin-right: 0px;
      }
    }

    &.elv-valuation-crypto-table-row__cell-index .cell {
      color: #aaafb6;
      font-family: 'Barlow';
      font-size: 11px;
      font-style: normal;
    }

    .elv-valuation-crypto-table-row__cell-activity {
      font-weight: 500;
    }

    &.el-table-column--selection {
      .cell {
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
      }
    }

    .elv-valuation-crypto-table-row__cell-desc {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      word-break: break-word;
    }

    .elv-valuation-crypto-cell__status {
      display: flex;
      height: 22px;
      width: fit-content;
      padding: 0px 8px;
      box-sizing: border-box;
      align-items: center;
      gap: 4px;
      border-radius: 14px;
      border: 1px solid #d0d4d9;
      background: #edf0f3;
      color: #636b75;

      svg {
        margin-right: 4px;
      }

      &.is-approved {
        border-color: #a9dc78;
        background: #f3fceb;
        color: #50970b;
      }
    }

    .elv-valuation-crypto-table-row__cell-button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        cursor: pointer;
        fill: #838d95;

        &.is-disabled {
          cursor: not-allowed !important;
          fill: #edf0f3 !important;
        }

        &:first-of-type {
          margin-right: 8px;
        }

        &:hover {
          fill: #1e2024;
        }
      }
    }
  }

  .el-table__footer-wrapper {
    .el-table__footer {
      height: 42px;

      .cell.elv-valuation-crypto-table-row__total {
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .cell.elv-valuation-crypto-table-row__amount {
        color: #0e0f11;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;

        p {
          color: #838d95;
          font-family: 'Barlow';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: none;
        }
      }
    }
  }
}

.elv-valuation-crypto-pagination__sizes-popper.el-popper {
  transform: translateY(10.5px);
}
</style>
<style lang="scss" scoped>
.elv-valuation-crypto-table-wrapper-container {
  flex: 1;
  min-height: 0px;
  position: relative;
}
</style>
